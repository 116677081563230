@import '~aos/dist/aos.css';
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import 'styles/components';
@import 'styles/mui';

body {
  font-size: 1rem;
  font-family: Nunito, sans-serif !important;
}

main {
  min-height: calc(100vh - 80px - 64px);
  background-color: #f6f6f6cc;
}

* {
  outline: none !important;
}

a:hover {
  text-decoration: none !important;
}

body > iframe {
  display: none;
}

:root {
  --color-primary: #ffde48;
  --color-secondary: #fc9669;
  --color-info: #3fb2ff;
  --color-action: #cecece;
}

@font-face {
  font-family: 'Hornbill';
  src: url('/assets/fonts/Hornbill-Black.otf') format('truetype');
  font-weight: 900;
}
@font-face {
  font-family: 'Hornbill';
  src: url('/assets/fonts/Hornbill-Bold.otf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Hornbill';
  src: url('/assets/fonts/Hornbill-Regular.otf') format('truetype');
  font-weight: 400;
}
