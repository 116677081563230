.MuiFormControl-root {
  > label {
    margin-bottom: 2px;
  }
  .MuiFormHelperText-root {
    margin: 2px 0px 0px;
    font-size: 14px;
  }
}

.MuiTableBody-root > .MuiTableRow-root:last-child > .MuiTableCell-root {
  border-bottom: none;
}

.MuiDialog-root {
  > .MuiDialog-container > .MuiPaper-root {
    border-radius: 12px;
    max-height: calc(100% - 128px);
    padding: 24px;
  }

  @media (max-width: 600px) {
    > .MuiDialog-container > .MuiPaper-root {
      margin: 12px;
      width: calc(100% - 24px);
      padding: 24px 6px;
    }
  }

  .MuiDialogTitle-root {
    padding: 12px 16px;
    font-size: 24px;
    font-weight: 700;
  }
  .MuiDialogContent-root {
    padding: 16px;
  }
  .MuiDialogActions-root {
    padding: 12px 16px;
  }
}

.MuiLoadingButton-root {
  .MuiLoadingButton-loadingIndicator {
    position: static;
    left: 0px;
    margin-left: 4px;
  }

  &.MuiLoadingButton-loading {
    color: inherit !important;
  }
}

.SnackbarContainer-top {
  top: 92px !important;
}

.MuiMenu-root,
.MuiPopover-root {
  > .MuiPaper-root {
    overflow: visible;
    margin-top: 12px;
    min-width: 180px;

    .MuiMenuItem-root {
      min-height: 40px;
      font-weight: 700;
      color: #000a;
      font-size: 14px;
    }
  }

  > .MuiPaper-root::before {
    content: '';
    display: none;
    z-index: 0;
    position: absolute;
    top: 0px;
    right: 14px;
    width: 10px;
    height: 10px;
    background-color: #fff;
    transform: translateY(-50%) rotate(45deg);
  }
}

.MuiButton-root.MuiButton-contained {
  &:hover {
    filter: brightness(0.85);
  }
  &.MuiButton-containedPrimary {
    background: linear-gradient(180deg, #ffde48 0%, #ffbd0a 100%);
    color: #722828;
  }
  &.MuiButton-containedSecondary {
    background: linear-gradient(180deg, #fc9669 0%, #f33935 100%);
    color: #fff;
  }
  &.MuiButton-containedInfo {
    background: linear-gradient(180deg, #3fb2ff 0%, #2380ff 100%);
    color: #fff;
  }
}

.MuiChip-label {
  font-weight: 700;
}
