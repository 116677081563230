$base-border-color: #d9f2fd;

.my-calendar {
  max-width: 1440px;
  margin: auto;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  .rbc-month-view {
    border: none;
    border-bottom: 1px solid $base-border-color;
  }
  .rbc-row.rbc-month-header {
    min-height: 60px;
    background: linear-gradient(90.86deg, rgba(125, 250, 245, 0.2) 35.24%, rgba(183, 121, 255, 0.2) 137.91%);
    border: 1px solid $base-border-color;
    border-radius: 24px 24px 0px 0px;
    flex: none;
    order: 0;
    flex-grow: 0;
    .rbc-header {
      display: flex;
      justify-content: center;
      flex-direction: column;
      border-color: transparent;
      span {
        line-height: 24px;
        color: #0b2f51;
      }
    }
  }
  .rbc-month-row {
    border-color: $base-border-color;
    border-right: 1px solid $base-border-color;
    border-left: 1px solid $base-border-color;
    flex-basis: auto;
    height: fit-content;
    min-height: 100px;
    .rbc-row-bg {
      .rbc-day-bg {
        border-color: $base-border-color;
        background: #fff;
      }
    }
    .rbc-button-link {
      padding: 8px;
      cursor: default;
      font-size: 14px;
      pointer-events: all !important;
      @media (max-width: 800px) {
        font-size: 10px;
      }
    }
    .rbc-row-content {
      .rbc-row {
        .rbc-date-cell {
          padding-bottom: 8px;
          a {
            font-size: 14px;
          }
        }
        &:last-child {
          .rbc-row-segment {
            padding-bottom: 8px;
          }
        }
        .rbc-row-segment {
          padding-bottom: 4px;

          .rbc-show-more {
            margin-left: 8px;
            color: #0a1316;
            font-weight: 400;
            font-size: 14px;
            text-decoration: inherit;
          }
          .rbc-event {
            border-radius: 8px;
            display: flex;
            padding: 8px;
            &.rbc-event-continues-prior {
              .event-dot,
              .rbc-event-content {
                visibility: hidden;
              }
            }
            &.custom-event-container {
              .rbc-event-content {
                color: #0a1316;
                font-size: 16px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                @media (max-width: 800px) {
                  font-size: 10px;
                }
              }
              .event-dot {
                width: 8px;
                height: 20px;
                min-width: 8px;
                min-height: 24px;
                margin-right: 8px;
                border-radius: 10px;
                @media (max-width: 800px) {
                  width: 4px;
                  height: 16px;
                  border-radius: 4px;
                  min-width: 4px;
                  min-height: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}
